import React, { useEffect } from "react";
import Loading from "../../components/layout/loading/Loading";

const Immo = () => {
  const url =
    "https://houseandcar.shop/search?type=Immobilier&ville=&inspection=&min_prix=0&max_prix=60&pour=&quoi=&prix=";

  useEffect(() => {
    window.location.href = url;
  }, []);

  return <Loading isLoading />;
};

export default Immo;
