import React, { useEffect } from "react";
import Loading from "../../components/layout/loading/Loading";

const WebsitePage = () => {
  const url =
    "https://play.google.com/store/apps/details?id=com.grandoos.housecar";

  useEffect(() => {
    window.location.href = url;
  }, []);

  return <Loading isLoading />;
};

export default WebsitePage;
