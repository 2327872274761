import { Route, Routes } from "react-router-dom";
import LoginPage from "../pages/auth/loginPage";
import { Autos, Immo, WebsitePage } from "../pages/qrcode";

function AuthRouter() {
  return (
    <div className="flex w-full h-full bg-white  overflow-scroll  ">
      <Routes>
        <Route path="*" element={<LoginPage />} />
        <Route path="/qrcode" element={<WebsitePage />} />
        <Route path="/qrcode/auto" element={<Autos />} />
        <Route path="/qrcode/immo" element={<Immo />} />
      </Routes>
    </div>
  );
}

export default AuthRouter;
